import { Component, Input } from '@angular/core';
import { TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle} from "@ng-bootstrap/ng-bootstrap";
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-language',
  standalone: true,
  imports: [
    NgbDropdownItem,
    NgClass,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgbDropdown,
    TranslocoPipe,
    NgIf
  ],
  templateUrl: './language.component.html',
  styleUrl: './language.component.scss'
})
export class LanguageComponent {
  constructor(private translocoService: TranslocoService){
  }

  @Input() location: 'footer' | 'login' = 'footer'

  setLang(lang: 'es' | 'en' | string){
    this.translocoService.setActiveLang(lang);
  }

  getCurrentLang(){
    return this.translocoService.getActiveLang()
  }
}
