<div ngbDropdown>
  <button type="button" class="btn bg-transparent text-decoration-underline px-0 border-0 fw-normal fs-5" [ngClass]="location === 'login' ? 'login-button' : 'footer-button'"
          id="dropdownConfig" ngbDropdownToggle>
    {{ 'FOOTER.texts.language' | transloco }}
    <i *ngIf="location === 'footer'" class="fa-solid fa-chevron-up ps-1" style="font-size: 10px"></i>
    <i *ngIf="location === 'login'" class="fa-solid fa-chevron-down ps-1" style="font-size: 10px"></i>
  </button>
  <div class=" custom-dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownConfig" style="font-size: 12px;"
       [ngClass]="location === 'login' ? 'login-dropdown' : ''">
    <button class="ps-2" style="width: 50px;" [ngClass]="getCurrentLang() === 'es' ? 'active' : ''"
            (click)="setLang('es')" ngbDropdownItem>ESP
    </button>
    <button class="ps-2" style="width: 50px;" [ngClass]="getCurrentLang() === 'en' ? 'active' : ''"
            (click)="setLang('en')" ngbDropdownItem>ENG
    </button>
  </div>
</div>
